import React from "react"
import { Link, graphql } from "gatsby"
import Helmet from "react-helmet"
import Template from "../components/layout"
import WishList from "../components/wishList"
import SEO from "../components/seo"
import Img from "gatsby-image"
import get from "lodash/get"

const style = ({
  title: {
    textDecoration: 'none'
  }
})

class blogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const posts = get(this, "props.data.allContentfulBlogPost.edges")
    const places = get(this, "props.data.allContentfulWishPlace.edges")
    const placeArray2 = places.map(pl => {
      return pl.node.name
    })
    const placeArray = places.map(({ node }) => {
      return (
        <div className="col-lg-6">
          {node.name}
        </div>
      )
    })
    return (
      <Template>
        <Helmet title={siteTitle} />
        <div className="indexpage">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="row blogRow">
                  {posts.map(({ node }) => {
                  return (
                    <div className="col-lg-6">
                      <article className="blog-listing" key={node.slug}>
                        <Link to={node.slug} className="entry-read-more">
                          <h2 className="entry-title">
                            {node.title}
                            <div className="entry-tags">
                              {node.tagline}
                            </div>
                          </h2>
                          <div className="entry-media">
                            <img className="entry-img" src={node.heroImage.fluid.src}/>
                          </div>
                          <p className="entry-content">
                            {node.description.description}
                          </p>
                        </Link>
                      </article>
                    </div>
                  )
                })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    )
  }
}

export default blogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulWishPlace {
      edges {
        node {
          image {
            fluid {
              src
            }
          }
          name
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          tagline
          slug
          description {
            description
          }
          heroImage {
            fluid {
              src
            }
          }
          body {
            body
          }
        }
      }
    }
  }
`
